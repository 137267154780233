import React from 'react';
import './TopBar.css'; // or you can use styled-components
// import logo from './logo.svg';
// import './logopurple.png';

const TopBar = () => {
  return (
    <div className="topbar">
      <div className="logo-section">
        <img src='/logopurple.png' alt="Logo" className="logo" />
        <h1 className='title'>Myt</h1>
      </div>
      <div className="nav-links">
        <a href="/">Home</a>
        
        {/* <a href="/dashboard">Dashboard</a> */}
        <a href="/privacypolicy">Privacy policy</a>
        <a href="/contactus">Contact us</a>
        
        {/* <a href="/puremap">Puremap</a> */}
        {/* <a href="/contact">Contact</a> */}
      </div>
    </div>
  );
};

export default TopBar;
