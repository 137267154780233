import React, { useState } from 'react';
import TopBar from '../../components/topbar/TopBar.js';
import { db } from '../../firebase'; // Import your Firestore setup
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import './ContactUs.css';

const ContactUs = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Add form data to Firestore
            await addDoc(collection(db, 'ContactUsMsgs'), {
                Name: name,
                Email: email,
                Message: message,
                TimeStamp: serverTimestamp(),
                Stage: 'New'
            });

            // Clear the form and show success message
            setName('');
            setEmail('');
            setMessage('');
            setSuccessMessage('Message sent successfully!');
            setErrorMessage('');
        } catch (error) {
            setErrorMessage('Error sending message, please try again.');
            setSuccessMessage('');
        }
    };

    return (
        <div className="contactus">
            <TopBar />
            <div className="contactus-container">
                <h1>Contact Us</h1>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message:</label>
                        <textarea
                            id="message"
                            name="message"
                            rows="5"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <button type="submit" className="submit-button">Submit</button>
                    {successMessage && <p className="success-message">{successMessage}</p>}
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                </form>
            </div>
        </div>
    );
};

export default ContactUs;
