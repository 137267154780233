// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Your web app's Firebase configuration (you can get this from the Firebase console)
const firebaseConfig = {
  apiKey: "AIzaSyDugy5XuwMTBn9VA82iB7qfsNItCLXqrKE",
  authDomain: "dating-kebxvs.firebaseapp.com",
  projectId: "dating-kebxvs",
  storageBucket: "dating-kebxvs.appspot.com",
  messagingSenderId: "620441671913",
  appId: "1:620441671913:web:5afd1f1c79245290ab7878"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// window.FIREBASE_APPCHECK_DEBUG_TOKEN = true ;
window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.APP_CHECK_DEBUG_TOKEN_FROM_CI;

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LfkA1gqAAAAAO7AcrNwfBNc5BdmjefRlqCJDTJ_'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
const db = getFirestore(app);

export { db };
