import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  APIProvider,
  Map,
  AdvancedMarker,
  useMap,
  Pin
} from '@vis.gl/react-google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

// Function to determine marker color based on gender
const getMarkerColor = (gender) => {
  switch (gender.toLowerCase()) {
    case 'male':
      // return '#4285F4'; // Blue
      //light blue 
      return '#00BFFF';
    case 'female':
      // return '#DB4437'; // Red
      // light red 
      return '#FF6347';
    case 'other':
      return '#F4B400'; // Yellow
    default:
      return '#0F9D58'; // Green for unspecified or other genders
  }
};

// PoiMarkers Component
const PoiMarkers = ({ pois }) => {
  const map = useMap();
  const [markers, setMarkers] = useState({});
  const clusterer = useRef(null);

  // Initialize MarkerClusterer when the map is available
  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({ map });
    }
  }, [map]);

  // Update clusters when markers change
  useEffect(() => {
    if (clusterer.current) {
      clusterer.current.clearMarkers();
      clusterer.current.addMarkers(Object.values(markers));
    }
  }, [markers]);

  // Manage marker references
  const setMarkerRef = (marker, key) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;

    setMarkers(prev => {
      if (marker) {
        return { ...prev, [key]: marker };
      } else {
        const newMarkers = { ...prev };
        delete newMarkers[key];
        return newMarkers;
      }
    });
  };

  return (
    <>
      {pois.map(poi => {
        // Ensure lat and lng are numbers
        const lat = Number(poi.location.lat);
        const lng = Number(poi.location.lng);

        if (isNaN(lat) || isNaN(lng)) {
          console.error(`Invalid location for poi key: ${poi.key}`, poi.location);
          return null; // Skip rendering this marker
        }

        return (
          <AdvancedMarker
            key={poi.key}
            position={{ lat, lng }}
            ref={marker => setMarkerRef(marker, poi.key)}
            clickable={false}
          >
            <Pin 
              background={getMarkerColor(poi.gender)} 
              glyphColor='#FFFFFF' 
              borderColor='#000000' 
            />
          </AdvancedMarker>
        );
      })}
    </>
  );
};

PoiMarkers.propTypes = {
  pois: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      location: PropTypes.shape({
        lat: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ]).isRequired,
        lng: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ]).isRequired
      }).isRequired,
      gender: PropTypes.string.isRequired
    })
  ).isRequired
};

// Puremap Component
const Puremap = ({ locations, defaultCenter, defaultZoom = 13 }) => {
  return (
    <div style={{ height: '800px', width: '100%' }}>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <Map
          defaultZoom={defaultZoom}
          mapId='demoMap'
          defaultCenter={defaultCenter}
        >
          <PoiMarkers pois={locations} />
        </Map>
      </APIProvider>
    </div>
  );
};

// Define PropTypes for Puremap
Puremap.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      location: PropTypes.shape({
        lat: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ]).isRequired,
        lng: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ]).isRequired
      }).isRequired,
      gender: PropTypes.string.isRequired
    })
  ).isRequired,
  defaultCenter: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }).isRequired,
  defaultZoom: PropTypes.number
};

// Default Props for Puremap (Optional)
Puremap.defaultProps = {
  defaultZoom: 13
};

export default Puremap;
