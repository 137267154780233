// Dashboard.js
import React, { useState, useEffect } from 'react';
import TopBar from '../../components/topbar/TopBar.js';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import { db } from '../../firebase'; // Firestore setup
import { collection, query, where, getDocs } from 'firebase/firestore';
import Puremap from '../../components/Puremap/Puremap.js';
import './Dashboard.css'; // Added styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

// Removed unused imports

const Dashboard = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null);  // For holding the authenticated user
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);  // Hold Firestore data
  const [defaultCenter, setDefaultCenter] = useState({ lat: 43.6532, lng: -79.3832 }); // Default to Toronto

  const auth = getAuth();

  // Handle login with email and password
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(''); // Clear previous errors
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
    } catch (error) {
      setError('Login failed. Please check your credentials.');
      console.error("Login error:", error);
    }
    setLoading(false);
  };

  // Monitor user authentication status
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user); // Set user if logged in
        fetchUsers(); // Fetch users once the user is logged in
      } else {
        setUser(null); // Clear user if not logged in
        setUsers([]); // Clear users data
      }
    });

    return () => unsubscribe();
  }, [auth]);

  // Fetch users from Firestore
  const fetchUsers = async () => {
    try {
      const userQuery = query(
        collection(db, 'users'),
        where('DeviceActive', '==', true)
      );
      const querySnapshot = await getDocs(userQuery);
      const userData = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          location: data.stableLocationLatLng,
          gender: data.gender
        };
      });
      setUsers(userData); // Set the users data to state
    } catch (err) {
      console.error("Error fetching users:", err);
      setError('Failed to fetch user data.');
    }
  };

  // Handle sign out
  const handleLogout = () => {
    signOut(auth).then(() => {
      setUser(null);
      setUsers([]); // Clear users data on logout
    }).catch((error) => {
      console.error("Sign out error:", error);
      setError('Failed to sign out.');
    });
  };

  // Prepare markers based on user data
  const markers = users.map(user => ({
    key: user.id,
    location: {
      lat: Number(user.location.latitude),
      lng: Number(user.location.longitude)
    },
    gender: user.gender
  }));

  // Use effect to get browser's current location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setDefaultCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        (error) => {
          console.warn("Geolocation error:", error);
          // If there's an error (e.g., permission denied), retain the default center (Toronto)
        }
      );
    } else {
      console.warn("Geolocation is not supported by this browser.");
      // If geolocation is not supported, retain the default center (Toronto)
    }
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <div className="dashboard">
      <TopBar />
      {user && (
        <nav className="sidebar">
          <ul className='sidebarElements'>
            {/* <li> */}
              <button onClick={handleLogout} className="logout-button">
              <FontAwesomeIcon icon={faSignOutAlt} />
                Logout
              </button>
            {/* </li> */}
          </ul>
        </nav>
      )}

      <main className={`content ${!user ? 'no-sidebar' : ''}`}>
        {user ? (
          <Puremap
            locations={markers}
            defaultCenter={defaultCenter}
            defaultZoom={4} // Optional: Adjust zoom level as needed
          />
        ) : (
          // <div className="login-container" style={{width:'50%'}}>
          <div className="login-container" >
            <h1>Login</h1>
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button className='login-button' type="submit" disabled={loading}>
                {loading ? 'Logging in...' : 'Login'}
              </button>
            </form>
          </div>
        )}
      </main>
    </div>
  );
};

export default Dashboard;
