import React, { useState } from 'react';
import { collection, addDoc,serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase'; // Make sure to adjust this path based on your structure
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TopBar from '../../components/topbar/TopBar.js';
import './LandingPage.css';

const FeatureSection = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };

  return (
    <Slider {...settings} >
      <div className="feature-container">
        {/* add text */}
        rem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
      </div>
      <div className="feature-container">Feature 2
        rem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
      </div>
      <div className="feature-container">Feature 3
        rem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
      </div>
    </Slider>
  );
};

const LandingPage = () => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add the email to the "emails" collection in Firestore
      await addDoc(collection(db, 'waitinglist'), { email,
        TimeStamp: serverTimestamp(),
       });
      setSuccessMessage('Successfully added to the waiting list!');
      setEmail(''); // Clear the email input
    } catch (error) {
      setErrorMessage('Error adding email. Please try again.');
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div className="container" style={{
      // backgroundImage: `url('/background.png')`,  
      // backgroundSize: 'cover',                     
      // backgroundPosition: 'center',                
    }}>
      {/* Top Bar */}
      <TopBar />

      {/* Add section for a drawing and text */}
      <section className="section">
        {/* <img src='/couple.webp' alt="Drawing" className='main-img' /> */}
        <img src='/ff.webp' alt="Drawing" className='main-img' />
        <div >
          <h1>Myt</h1>
          <p>Myt is introducing a new approach to dating. Swiping left and right is fun, but wouldn’t it be better to meet the right match faster and in a more organic way?</p>
          <p>More will be announced soon!</p>
          {/* <p>Add your email to the waiting list to hear more!</p> */}
        </div>
      </section>

      {/* Feature Section */}
      {/* <FeatureSection /> */}

      {/* Subscribe Section */}
      {/* <div className="subscribe-section">
        <form className="subscribe-form" onSubmit={handleSubmit}>
          <label>Subscribe to our waiting list</label>
          <input
            className="input"
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button className="submit-button" type="submit">Submit</button>
        </form>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div> */}

      {/* Download Buttons */}
      {/* <div className="download-buttons">
      
      <a className="download-button" target="_blank" rel="noopener noreferrer" >
        <img src='/apple.png' alt="Apple Store" className='download-buttom-img' />
      </a>
      <a className="download-button" href="https://play.google.com" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', textDecoration: 'none' }}>

        <img src='/google.png' alt="Google Play" className='download-buttom-img' />
      </a>
    </div>  */}



    </div >

    
  );
};

export default LandingPage;
